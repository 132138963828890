import React, { useState } from 'react';
import { AuthenticationForm } from './AuthenticationForm';
import { useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { ForgotPassword_Mutation } from '@netfront/gelada-react-shared';
import { Input, Label } from 'components/Form';

export function ForgotPasswordForm() {
  const router = useRouter();
  const [email, setEmail] = useState<any>('');

  const [callMutation, { loading: isLoading, error }] = useMutation(ForgotPassword_Mutation, {
    context: { clientName: 'auth' },
    onCompleted: () => {
      router.push('/forgot-password-success');
    },
  });

  const sendRequest = (e: any) => {
    e.preventDefault();
    if (email === '') return;
    callMutation({ variables: { email } })
      // do nothing with the error as we use the message from the mutation
      .catch(() => {return;});
  };

  const lazyRequest = { sendRequest, isLoading, error };

  return (
    <AuthenticationForm ctaName="Get Reset Link" lazyRequest={lazyRequest}>
      <Label htmlFor="email">Email:</Label>
      <Input required id="email" type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} />
    </AuthenticationForm>
  );
}
